import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, Video } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`In diesem Artikel:`}</h4>
    <ul>
      <li parentName="ul">{`Wie man Trails in gutem Zustand findet 🔎`}</li>
      <li parentName="ul">{`Trails mit geringem Aufwand erhalten 💡`}</li>
      <li parentName="ul">{`Gewinne 2 Norrøna fjøra MTB Produkte & 5 Trailguide Jerseys 🏆`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://trailguide.no/news/preserveourtrails/"
        }}>{`Article in english`}</a></li>
    </ul>
    <br />
    <br />
    <h4>{`❔ "Mountainbiken macht die Trails kaputt" - Stimmt das?`}</h4>
    <p>{`Die Bike Saison hat begonnen, der Schnee ist geschmolzen. Auch Regen in der Saison lässt
manche Trails nass und matschig werden. Es macht wenig Spaß durch tiefe Pfützen oder
große Schlammlöcher zu fahren.`}</p>
    <br />
    <p>{`In einer im Januar 2021 veröffentlichte `}<a parentName="p" {...{
        "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
      }}>{`Studie`}</a>{`
aus Norwegen heißt es: `}<i>{`Fahrradfahrer und Wanderer haben einen geringen Einfluss
auf die Abnutzung von Wegen - So lange es trocken ist!`}</i>{` Sobald die Erde feucht und
nass ist, haben besonders Mountain Biker einen deutlich größeren Einfluss auf die
Erosion.`}</p>
    <Image src="/news/_DSC5668.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <p>{`Es macht nicht nur weniger Spaß einen ausgefahrenen und kaputten Trail zu fahren,
es kann auch der Natur schaden. Erosion legt Wurzeln frei, im schlimmsten fall können
sogar die Bäume beschädigt werden. Tiefe Reifenspuren in der nassen Erde sind
weithin sichtbar. Das Konfliktpotential mit anderen Interessensgruppen steigt.
Was können wir tun um das zu vermeiden?`}</p>
    <br />
    <br />
    <h4>{`💡 Eine Möglichkeit ist die Wege anzupassen: Mineralische Materialien & Drainagen`}</h4>
    <ul>
      <li parentName="ul">{`In der `}<a parentName="li" {...{
          "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
        }}>{`Studie`}</a>{`
heißt es: Organische Bestandteile im Boden und fehlende Drainagen sind Ursachen für
problematische Bereiche.`}</li>
      <li parentName="ul">{`Die meisten Trails in Europa sind keine speziell zum Biken gebauten Wege. Manchmal
könnten Drainagen gebaut werde ohne den Charakter des Weges zu verändern. Spätestens
wenn man mineralische Erde wie z.B. Schotter und Sand einbringt und die Oberfläche
verdichtet, verlieren aber wohl die meisten Trails einen Großteil dessen was den
Reiz des Mountainbikens ausmacht.`}</li>
      <li parentName="ul">{`Mangels Ressourcen und aus rechtlichen Gründen kann aber man verständlicherweise
nicht alle Wege verändern.`}</li>
      <li parentName="ul">{`Trails werden normalerweise nur von ein paar Leuten gebaut und instand gehalten.
Die folgende Möglichkeit kann aber leicht jeder berücksichtigen:`}</li>
    </ul>
    <br />
    <br />
    <h4>{`💡 Trails in trockenem Zustand finden, nasse Wege vermeiden`}</h4>
    <ul>
      <li parentName="ul">{`Die `}<a parentName="li" {...{
          "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
        }}>{`Studie`}</a>{`
zeigt: Nässe hat den größten Einfluss auf die Abnutzung.`}</li>
      <li parentName="ul">{`Aktuelle Infos sind hier entscheidend um trockene Trails zu finden. Während manche
schnell abtrocknen, bleiben Andere lange nass. Condition Reports helfen um eine gute
Entscheidung zu treffen.`}</li>
      <li parentName="ul">{`Das hilft nicht nur die Wege zu erhalten, es macht auch einfach mehr Spaß
Trails in gutem Zustand zu fahren.`}</li>
      <li parentName="ul">{`Lokale Trailbau Organisationen profitieren von den Condition Reports. Sie erhalten
aktuelle Infos über ihre Trails, und können schnell auf Gefahren reagieren wenn z.B.
ein Baum auf den Weg gefallen ist, oder etwas anderes Kaputt ist.`}</li>
      <li parentName="ul">{`Das Konfliktpotential mit anderen Interessensgruppen im Wald kann reduziert werden.`}</li>
      <li parentName="ul">{`Trails sind "nicht einfach da". Sie müssen gebaut und instand gehalten werden.
Die Abnutzung zu minimieren hilft diesen Aufwand und die Kosten so gering wie möglich zu halten.`}</li>
    </ul>
    <Image src="/news/DSC_9207.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`🔎 Wie man Trails in gutem Zusand findet & aktuelle Bedingungen meldet`}</h4>
    <div className="flex justify-center">
  <Video className="w-96" src="cSPln27Zkh4" style={{
        height: "17rem",
        paddingBottom: "0px"
      }} mdxType="Video" />
    </div>
    <ul>
      <li parentName="ul">{`Auf Trailguide kann man einfach die Condition Reports der Trails sehen.`}</li>
      <li parentName="ul">{`Filter für Trails die aktuell in gutem Zustand sind.`}</li>
      <li parentName="ul">{`Bedingungen melden (Condition Reports) dauert wenige Sekunden.`}</li>
    </ul>
    <br />
    <br />
    <h4>{`🏆 Gib Condition Reports ab und gewinne 2 Norrøna fjøra MTB Produkte & 5 Trailguide Jerseys`}</h4>
    <p>{`Um unseren Sponsor `}<a parentName="p" {...{
        "href": "https://norrona.com"
      }}>{`Norrøna`}</a>{` zu zitieren: "Without nature, there's no us" -
Wir würden dem hinzufügen "Ohne Trails gibt es kein Mountainbiken".
Bedingungen zu posten hilft Trails in besserem Zustand zu halten und,
dass man Mountainbiken mehr genießen kann.`}<br /><br /><i>{`Norrøna und Trailguide
verlosen zwei tolle Artikel der fjøra Mountainbike Kollektion und fünf
Trailguide Jerseys unter allen die im Juni und July 2021 auf `}<a parentName="p" {...{
          "href": "https://trailguide.net"
        }}>{`trailguide.net`}</a>{`
einen Condition Report abgeben.`}</i></p>
    <Image src="/news/norrona_fjora_2.jpeg" className="w-full mx-auto my-12" mdxType="Image" />
    <br />
    <h4>{`📜 Infos zur Studie:`}</h4>
    <p>{`Name: Effects of mountain biking versus hiking on trails under different environmental conditions`}</p>
    <p>{`Authoren: Marianne Evju, Dagmar Hagen, Mari Jokerud, Siri Lie Olsen, Sofie Kjendlie Selvaag, Odd Inge Vistad`}</p>
    <p>{`Veröffentlicht in: Journal of Environmental Management,
Volume 278, Part 2,
2021,
111554,
ISSN 0301-4797`}</p>
    <p>{`Link: `}<a parentName="p" {...{
        "href": "https://www.sciencedirect.com/science/article/pii/S0301479720314791"
      }}>{`https://www.sciencedirect.com/science/article/pii/S0301479720314791`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      